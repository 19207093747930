import { ApolloClient, useApolloClient, useQuery } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { useAtom } from "jotai";
import decode from "jwt-decode";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { ModuleInstance } from "@/App/app-modules/modules-utils";
import { useRouter } from "@/App/Router";
import RepresentativeAccessModal from "@/components/Modals/RepresentativeAccessModal";
import { GetUser } from "@/graphql/__generated__/GetUser";
import {
  authAtom,
  forbiddenErrorAtom,
  showRepresentativeAccessModalAtom,
} from "@/store";
import { captureException } from "@/utils/error-logging";
import request from "@/utils/request";
import { getCurrentPageURL } from "@/utils/url-helpers";
import { AuthViewModel } from "@/view-models/AuthViewModel";
import { MetaViewModel } from "@/view-models/MetaViewModel";
import { ModuleViewModel } from "@/view-models/ModuleViewModel";

import {
  extractHashTokens,
  readRefreshToken,
  readToken,
  updateTokens,
} from "./auth-utils";
import AuthMessages, { UserAuthMessage } from "./AuthMessages/AuthMessages";
import { logout, securityLoggedOut } from "./logout-utils";
import { getUserQuery } from "./queries/baseQueries";

const MONITOR_EVENTS = ["click", "wheel", "touchstart", "keydown"];

/** Check if the user is still active after this amount of milliseconds (default 5 minutes)*/
const INACTIVE_CHECK_TIME = 5 * 60 * 1000;

/** Time in milliseconds before forced logged out to warn the user.*/
const WARNING_TIME = 5 * 60 * 1000;

const FORBIDDEN_CATEGORY = "privilege_error";

let tokenCache = {};

type Props = {
  children: React.ReactNode;
  auth: AuthViewModel;
  initialModules: Array<ModuleInstance | ModuleViewModel>;
  meta: MetaViewModel;
};

type State = {
  checkIfActive: boolean;
  refreshingToken: boolean;
  authMessage: UserAuthMessage;
  messageExtra?: {
    [key: string]: any;
  };
};

type Action =
  | { type: "checkIfActive" | "refresh" | "refreshed" }
  | {
      type: "message";
      message: UserAuthMessage;
      extra?: {
        [key: string]: any;
      };
    };

const initialState = {
  checkIfActive: false,
  refreshingToken: false,
  authMessage: null,
};

function getTimeLeft(token: string, refreshToken: string) {
  if (!!token && !!refreshToken) {
    try {
      const now = Date.now();
      const tokenCached = !!tokenCache[token];
      // @ts-ignore
      const exp = tokenCache[token] || decode(token).exp * 1000;
      tokenCache[token] = exp;

      const refreshExp = refreshToken
        ? // @ts-ignore
          tokenCache[refreshToken] || decode(refreshToken).exp * 1000
        : undefined;
      tokenCache[refreshToken] = refreshExp;

      const timeleft = refreshExp ? Math.min(exp, refreshExp) - now : exp - now;

      if (
        (process.env.NODE_ENV === "development" || process.env.GRAPHQL_MOCK) &&
        !tokenCached
      ) {
        // Log the expiration time during development
        const expiresDate = new Date(exp);
        const dateFormatted = expiresDate.toLocaleDateString("en", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        const hours = expiresDate.getHours();
        const minutes =
          expiresDate.getMinutes() > 9
            ? expiresDate.getMinutes()
            : "0" + expiresDate.getMinutes();

        console.log(
          `%cToken expires at: %c${dateFormatted}, ${hours}:${minutes}`,
          "font-weight: 300; color: #747589",
          "font-weight: 700; color: #14143C"
        );
      }

      if (!isNaN(timeleft) && timeleft > 0) {
        // setTimeout to refresh the token after the specified time
        return timeleft;
      }
    } catch (error) {
      console.error("[user-session]", error.message);
    }
  }

  return 0;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "checkIfActive":
      return { ...state, checkIfActive: true };
    case "refresh":
      return { ...state, refreshingToken: true, checkIfActive: false };
    case "refreshed":
      return { ...state, refreshingToken: false };
    case "message":
      return {
        ...state,
        authMessage: action.message,
        messageExtra: action.extra,
      };
    default:
      return state;
  }
}

/**
 * Check if the URL contains access and refresh token on first load.
 * Extract them to the sessionStorage, and remove them from the URL
 */
function useExtractTokens() {
  const { replace, location } = useRouter();
  const mounted = React.useRef(false);
  let newHash: string | null | undefined = undefined;
  if (!mounted.current) {
    mounted.current = true;
    newHash = extractHashTokens(location.hash);
  }

  React.useEffect(() => {
    if (newHash !== undefined) {
      // Remove the hash from the URL. Replace so it doesn't affect history.
      replace(
        `${location.pathname}${location.search || ""}${
          !!newHash ? "#" + newHash : ""
        }`
      );
    }
  }, [location.pathname, location.search, newHash, replace]);
}

/**
 * UserSession is in charge of reading access_token, and refreshing it before it expires.
 * It's only refreshed if the user is active
 * */
function UserSession({ meta, children, initialModules, auth }: Props) {
  useExtractTokens();
  const client = useApolloClient() as ApolloClient<InMemoryCache>;
  const [{ user, authenticating }, setAuth] = useAtom(authAtom);
  const [, setRepresentativeModalOpen] = useAtom(
    showRepresentativeAccessModalAtom
  );
  const [, setForbiddenError] = useAtom(forbiddenErrorAtom);
  const [t, , ready] = useTranslation(["auth", "common"]);

  // Read the tokens from session storage on each render, so that we don't store them in memory
  const token = readToken();
  const refreshToken = readRefreshToken();

  const [noSession, setNoSession] = React.useState<boolean | null | undefined>(
    undefined
  );
  const history = useRouter();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { data, loading, error } = useQuery<GetUser, any>(getUserQuery, {
    fetchPolicy: "no-cache",
    skip: !token,
  });

  const [representativeModalProps, setRepresentativeModalProps] =
    React.useState<any>(null);
  const [representingSelf, setRepresentingSelf] = React.useState(false);
  React.useEffect(() => {
    setRepresentingSelf(
      JSON.parse(window.localStorage.getItem("representing_self") || "false")
    );
  }, []);

  // This is just to make sure the next useEffect doesn't run twice if 2 errors are checked in the below useEffect
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  // This useEffect checks if GetUser request returns a graphQL error matching the FORBIDDEN_CATEGORY
  // Then it logs out using the new logout param which triggers new content for the logout modal
  React.useEffect(() => {
    if (
      error?.graphQLErrors?.[0]?.extensions?.category === FORBIDDEN_CATEGORY &&
      !isLoggingOut
    ) {
      if (
        process.env.NODE_ENV === "development" ||
        process.env.GRAPHQL_MOCK === "true"
      ) {
        console.info("User without privileges. Logging out.");
      }
      setIsLoggingOut(true);
      setForbiddenError(true);
      setTimeout(
        () =>
          logout(auth.logout, meta?.currentArea, "?forbiddenloggedout=true"),
        1000
      );
    }
  }, [
    data,
    error,
    auth.logout,
    meta?.currentArea,
    setIsLoggingOut,
    isLoggingOut,
    setForbiddenError,
  ]);

  React.useEffect(() => {
    window.localStorage.setItem(
      "representing_self",
      representingSelf.toString()
    );
  }, [representingSelf]);

  const brugerSession = data?.bruger_session;

  /* Initial Mount effect - Check for logged out status */
  React.useEffect(() => {
    if (!loading) {
      const params = new URLSearchParams(history.location.search);

      let loggedOutMessage: UserAuthMessage | undefined = undefined;
      if (params.has("loggedout")) {
        params.delete("loggedout");
        loggedOutMessage = "loggedOut";
      } else if (params.get("securityloggedout")) {
        loggedOutMessage = "securityLoggedOut";
        params.delete("securityloggedout");
      } else if (params.has("forbiddenloggedout")) {
        loggedOutMessage = "forbiddenLoggedOut";
        params.delete("forbiddenloggedout");
      }

      if (loggedOutMessage) {
        history.replace(
          `${history.location.pathname}${params.toString()}${
            history.location.hash || ""
          }`
        );

        if (!data || !data.bruger_session) {
          window.localStorage.removeItem("representing_self");
          // Logged out, and the user is no longer valid
          dispatch({
            type: "message",
            message: loggedOutMessage,
          });
        }
      }
    }
  }, [loading, data, history, brugerSession]);

  React.useEffect(() => {
    /**
     * Check if the user is still active.
     * As soon as the user performs an interaction, dispatch the "refresh" action, so we update the access_token
     */
    if (state.checkIfActive && token) {
      const handleActivity = () => {
        dispatch({ type: "refresh" });
      };

      /**
       *  Check the remaining time every 30 seconds.
       *  We do it with an interval (instead of setTimeout) so we can ensure more precision.
       */
      const checkInterval = setInterval(() => {
        const timeLeft = getTimeLeft(token, refreshToken);

        if (!timeLeft) {
          if (state.authMessage !== "securityLoggedOut") {
            setAuth({
              authenticating,
              user,
              unauthenticating: true,
            });
            dispatch({
              type: "message",
              message: "securityLoggedOut",
              extra: {
                redirectUrl: meta.loginRedirectUrl || getCurrentPageURL(),
              },
            });
            window.localStorage.removeItem("representing_self");
            securityLoggedOut(history, meta, client);
          }
        } else if (
          timeLeft < WARNING_TIME &&
          state.authMessage !== "inactiveWarning"
        ) {
          // Show a timeout  to the user
          dispatch({ type: "message", message: "inactiveWarning" });
        }
      }, 30 * 1000);

      MONITOR_EVENTS.forEach((eventType) =>
        document.addEventListener(eventType, handleActivity, {
          passive: true,
        })
      );

      return () => {
        clearInterval(checkInterval);
        MONITOR_EVENTS.forEach((eventType) =>
          document.removeEventListener(eventType, handleActivity)
        );
      };
    }
  }, [
    state.checkIfActive,
    meta,
    token,
    refreshToken,
    client,
    history,
    state.authMessage,
    setAuth,
    authenticating,
    user,
  ]);

  const handleRefresh = React.useCallback(
    async function handleRefresh(isCurrent, representingSelf = false) {
      try {
        const data = await request(auth.refresh, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: refreshToken,
          }),
        });

        setRepresentingSelf(representingSelf);

        // Ensure we have a new token, and that it hasn't already expired
        if (isCurrent && data && data.access_token) {
          // Clear the token cache
          tokenCache = {};
          if (getTimeLeft(data.access_token, refreshToken) > 0) {
            updateTokens(data.access_token);
            dispatch({ type: "refreshed" });
          }

          return;
        }
      } catch (e) {
        captureException(e);
      }

      if (isCurrent) {
        setAuth({
          authenticating,
          user,
          unauthenticating: true,
        });
        dispatch({
          type: "message",
          message: "securityLoggedOut",
          extra: {
            redirectUrl: meta.loginRedirectUrl || getCurrentPageURL(),
          },
        });
        securityLoggedOut(history, meta, client);
      }
    },
    [
      auth.refresh,
      authenticating,
      client,
      history,
      meta,
      refreshToken,
      setAuth,
      token,
      user,
    ]
  );

  React.useEffect(() => {
    /**
     * The access_token needs to be refreshed now.
     **/
    if (state.refreshingToken && token && refreshToken) {
      let isCurrent = true;
      handleRefresh(isCurrent, representingSelf);

      return () => {
        isCurrent = false;
      };
    }
  }, [
    state.refreshingToken,
    auth,
    client,
    history,
    meta,
    refreshToken,
    token,
    handleRefresh,
    representingSelf,
  ]);

  React.useEffect(() => {
    if (noSession !== !token) {
      // We now know if there might be a valid session, so update the state
      setNoSession(!token);
    }

    setAuth({
      authenticating: loading || noSession === undefined,
      user: data ? data.bruger_session : undefined,
    });

    /**
     * If the user can represent and isn't representing and isn't representing itself show the representative modal
     * */
    if (
      data &&
      brugerSession?.kan_repraesentere &&
      brugerSession.kan_repraesentere.length > 0 &&
      !brugerSession.repraesenterer &&
      !representingSelf
    ) {
      return setRepresentativeModalOpen(true);
    }
    /**
     * If the user has a token, start a timeout to watch for interactions
     * */
    if (!loading && token && refreshToken) {
      if (!data || !data.bruger_session) {
        // A token has been set, but there's no valid user?!
        return;
      }

      const timeLeft = getTimeLeft(token, refreshToken);
      if (timeLeft < INACTIVE_CHECK_TIME) {
        // The token expires before we would refresh it. Let's refresh it now.
        dispatch({ type: "refresh" });
      } else {
        // Wait 5 minutes, then check if the user is still active before refreshing the token
        const refreshTimeout = setTimeout(() => {
          dispatch({ type: "checkIfActive" });
        }, INACTIVE_CHECK_TIME);

        return () => {
          clearTimeout(refreshTimeout);
        };
      }
    }
  }, [
    token,
    refreshToken,
    loading,
    data,
    noSession,
    setAuth,
    setRepresentativeModalOpen,
    brugerSession,
    representingSelf,
  ]);

  React.useEffect(() => {
    const headerModule = initialModules.find(
      (module) => module.name === "Header"
    );
    // @ts-ignore
    const headerModuleProps = headerModule?.props
      ? // @ts-ignore
        headerModule?.props
      : // @ts-ignore
        headerModule?.properties;
    const representativeModal = headerModuleProps?.login?.representativeModal;
    setRepresentativeModalProps(representativeModal);
  }, [initialModules, setRepresentativeModalProps]);

  function doLogOut() {
    const origin = window.location.origin;

    //Logout if "localhost" or "mocklogin"
    if (
      process.env.NODE_ENV === "development" ||
      origin.includes("https://umbraco") ||
      origin.includes("http://umbraco")
    ) {
      //Remove cookies
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      // Remove representing self
      window.localStorage.removeItem("representing_self");

      //Redirect to home page and add
      // @ts-ignore
      window.location = origin + "?loggedout=true";
    } else {
      logout(auth.logout, meta?.currentArea);
    }
  }

  const primaryLabel = ready ? t("common:choose") : "Vælg";
  const representativeModalPrimaryAction = {
    label: primaryLabel,
    variation: "primary",
  };
  const secondaryLabel = ready ? t("auth:logoutLabel") : "Log ud";
  const secondaryLabelRepresenting = ready ? t("common:cancel") : "Annuller";
  const representativeModalSecondaryAction = {
    onClick: () => {
      if (brugerSession?.repraesenterer) {
        setRepresentativeModalOpen(false);
      } else {
        doLogOut();
      }
    },
    label: brugerSession?.repraesenterer
      ? secondaryLabelRepresenting
      : secondaryLabel,
    variation: "text",
  };

  return (
    <>
      {children}
      {state.authMessage && (
        <AuthMessages
          auth={auth}
          extra={state.messageExtra}
          message={state.authMessage}
          meta={meta}
          onClosed={() => {
            setIsLoggingOut(false);
            setForbiddenError(false);
            dispatch({ type: "message", message: null });
          }}
        />
      )}
      {brugerSession?.kan_repraesentere &&
        brugerSession?.kan_repraesentere?.length > 0 && (
          <RepresentativeAccessModal
            actions={[
              representativeModalPrimaryAction,
              representativeModalSecondaryAction,
            ]}
            onSelectRepresentedOwner={(owner) => {
              let requestUrl = "";
              let representingSelf = false;

              if (owner === "egne-ejendomme") {
                representingSelf = true;
                if (brugerSession.repraesenterer)
                  requestUrl = auth.stopImpersonating;
              } else if (owner) {
                requestUrl = `${auth.impersonate}?sagsperson_id=${owner.id}&rolle=${owner.rolle}`;
              }

              const options = {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };

              (requestUrl ? request(requestUrl, options) : Promise.resolve())
                .then((_) => {
                  setRepresentativeModalOpen(false);

                  return handleRefresh(true, representingSelf);
                })
                .then(() => {
                  global.location.assign("/mine-ejendomme");
                })
                .catch((error) => console.error(error));
            }}
            {...representativeModalProps}
          />
        )}
    </>
  );
}

UserSession.defaultProps = {
  meta: {},
  auth: {
    login: "",
    logout: "",
    refresh: "",
  },
};

export default UserSession;
